import React from 'react'
//Shield components
import USBButton from '@usb-shield/react-button'
import USBButtonGroup from '@usb-inner-src/react-button-group'
import USBModal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@usb-shield/react-modal'

//Style Sheets
import styles from './SessionTimeOut.module.scss'
import { sessionTimeOutCTAFormValues } from './SessionTimeOut.content'
import '@usb-shield/react-modal/dist/library/styles/index.css'

export interface ModalProps {
  isModalOpen: boolean
  secondsLeft: number
  continueSessionOnClick: () => void
  sessionLogoutOnClick: () => void
}

const SessionTimeOutCTA = ({
  isModalOpen,
  secondsLeft,
  continueSessionOnClick,
  sessionLogoutOnClick,
}: ModalProps) => {
  const featureMFA: boolean = process.env.FEATURE_MFA !== 'false'
  const minsRemaining = parseInt(
    Math.floor((secondsLeft % 3600) / 60)
      .toString()
      .padStart(2, '0')
  )

  const secsRemaining = parseInt(
    Math.floor(secondsLeft % 60)
      .toString()
      .padStart(2, '0')
  )

  return (
    <>
      <USBModal
        isOpen={isModalOpen}
        handleClose={() => { }}
        id="timeout-modal"
        dataTestId="timeout-modal"
        className={styles.sessionTimeOutModal}
      >
        <ModalHeader id="timeout-mh">
          {sessionTimeOutCTAFormValues.modalData.title}
        </ModalHeader>

        <section className={styles.sessionTimeOutForm}>
          <ModalBody>
            <p className={styles.modalDescription}>
              For your security, you'll be logged out after
              {minsRemaining > 1
                ? ` ${minsRemaining} mins`
                : minsRemaining === 1
                  ? ` ${minsRemaining} min`
                  : ''}
              {` ${secsRemaining} secs`} of inactivity.
            </p>
          </ModalBody>
          <ModalFooter>
            <USBButtonGroup addClasses={'modal-button-group'}>
              <USBButton
                id="timeout-continue-btn"
                dataTestId={'timeOutContinueBtn'}
                type="submit"
                size="medium"
                ctaStyle="standard"
                emphasis="heavy"
                aria-describedby="submit form"
                addClasses={'usb-fleet-continue'}
                handleClick={continueSessionOnClick}
              >
                {sessionTimeOutCTAFormValues.modalData.buttonLabels.continue}
              </USBButton>
              <USBButton
                id="secondary-modal-button"
                ctaStyle="standard"
                emphasis="subtle"
                handleClick={sessionLogoutOnClick}
              >
                {sessionTimeOutCTAFormValues.modalData.buttonLabels.logout}
              </USBButton>
            </USBButtonGroup>
          </ModalFooter>
        </section>
      </USBModal>
    </>
  )
}
export default SessionTimeOutCTA
